import { createClient } from '@orbit/prismic'
import { Breadcrumbs } from '@orbit/ui/core'
import {
    components,
    OrigoFooterProps,
    NavigationProps,
} from '@orbit/ui/prismic'
import { asLink } from '@prismicio/helpers'
import { SliceLike, SliceZone, SliceZoneLike } from '@prismicio/react'
import {
    PrismicDocumentWithUID,
    RelationField,
    RichTextField,
    SliceZone as PrismicSlices,
} from '@prismicio/types'
import { GetStaticPaths, GetStaticProps } from 'next'
import PageLayout from '../layouts/PageLayout'
import { OrigoRoutes } from '../utils/routes'
import { arraysIdentical } from '../utils/arraysIdentical'
import { getFooter } from './api/getFooter'
import { getMenu } from './api/getMenu'
import { captureException } from '@sentry/nextjs'
import { MetaHead } from '../utils/MetaHead'

type TPageData = {
    uid: string
    title: RichTextField
    parent_page: RelationField
    body: any
    meta_title: string
    meta_description: string
    meta_image: string
}
interface IProps {
    page: PrismicDocumentWithUID<TPageData>
    menu: NavigationProps
    footer: OrigoFooterProps
    lang: 'is' | 'en-gb'
}

export const Page: React.FC<IProps> = ({ page, lang, menu, footer }) => {
    return (
        page && (
            <>
                <MetaHead
                    title={page.data.meta_title ? page.data.meta_title : ''}
                    description={
                        page.data.meta_description
                            ? page.data.meta_description
                            : ''
                    }
                    image={page.data.meta_image ? page.data.meta_image : ''}
                />
                <PageLayout menu={menu} footer={footer}>
                    <div>
                        <Breadcrumbs type="page" lang="is" />
                        <SliceZone
                            slices={page.data.body}
                            components={components}
                            context={{
                                lang: lang,
                                tags: page.tags,
                                uid: page.uid,
                                id: page.id,
                            }}
                        />
                    </div>
                </PageLayout>
            </>
        )
    )
}

export const getStaticProps: GetStaticProps<IProps> = async ({
    params,
    previewData,
}) => {
    const { uid } = params
    const prismicClient = createClient({
        previewData: previewData,
        routes: OrigoRoutes,
    })

    try {
        const page = await prismicClient.getByUID<
            PrismicDocumentWithUID<TPageData>
        >('page', Array.isArray(uid) ? uid[uid.length - 1] : uid, {
            lang: 'is',
        })

        const path = page.url.split('/')
        const uids = path.slice(1, path.length)

        if (!arraysIdentical(uid, uids)) return { notFound: true }

        const menu = await getMenu('is', previewData)
        const footer = await getFooter('is', previewData)

        return {
            revalidate: 10,
            props: {
                menu,
                footer,
                lang: 'is',
                page: page,
            },
        }
    } catch (error) {
        captureException(error, {
            tags: {
                page: 'Page',
                uid: Array.isArray(uid) ? uid.join('/') : uid,
                lang: 'is',
            },
        })
        return {
            notFound: true,
            revalidate: 10,
        }
    }
}

export const getStaticPaths: GetStaticPaths = async () => {
    const prismicClient = createClient({ routes: OrigoRoutes })
    const pages = await prismicClient.getAllByType<
        PrismicDocumentWithUID<TPageData>
    >('page', { pageSize: 100, lang: 'is' })

    const paths = pages.map((page) => asLink(page))

    return {
        paths: paths,
        fallback: true,
    }
}

export default Page
